.member-trend-table {
  height: 245px !important;
}
/* Trend Level */

.pic-circle-arrow-up {
  /* border-top-color: #006400; */
  border: 2px solid transparent;
  border-top-color: rgb(47, 225, 146);
  border-left-color: rgb(47, 225, 146);
  border-bottom-color: rgb(47, 225, 146);
  /* height: 49px;
  width: 49px; */
  border-radius: 50%;
  position: absolute;
  transform: rotate(38deg);
  margin: 0px auto;
}
.pic-circle-arrow-up .student-list-pic {
  transform: rotate(321deg);
}

.pic-circle-arrow-up:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 84%;
  height: 0;
  width: 0;
  border-left: 6px solid rgb(47, 225, 146);
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.pic-circle-arrow-down {
  /* border: 5px solid transparent; */
  border: 2px solid transparent;
  border-top-color: rgb(232, 122, 122);
  /* border-left-color: rgb(232, 122, 122); */
  border-bottom-color: rgb(232, 122, 122);
  border-right-color: rgb(232, 122, 122);
  /* height: 49px;
  width: 49px; */
  border-radius: 50%;
  position: absolute;
  transform: rotate(325deg);
  margin: 0px auto;
}
.pic-circle-arrow-down .student-list-pic {
  transform: rotate(35deg);
}

.pic-circle-arrow-down:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3%;
  height: 0;
  width: 0;
  border-left: 6px solid rgb(232, 122, 122);
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(33deg);
  transform: rotate(138deg);
}
.pic-circle-neutral {
  border: 2px solid transparent;
  border-top-color: rgba(255, 168, 0, 1);
  border-left-color: rgba(255, 168, 0, 1);
  border-right-color: rgba(255, 168, 0, 1);
  border-bottom-color: rgba(255, 168, 0, 1);
  /* height: 49px;
  width: 49px; */
  border-radius: 50%;
  position: absolute;
  transform: rotate(359deg);
  margin: 0px auto;
}

.member-trend .member-name {
  font-weight: 600;
  font-size: 16px;
  color: #182838;
}
.member-name {
  font-weight: 600;
  font-size: 16px;
  color: #182838;
}
.member-trend .member-service {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #b8b8b8;
}

.member-trend .member-tag {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  background-color: #ebeff2;
  color: #7f8188;
  margin: 2px 5px 2px 0px;
  white-space: nowrap;
  padding: 3px;
  border-radius: 4px;
  /* display: inline-block; */
}
/* Trend Level */
/* dashboard  analytics */
#dashboard-analytics .card-spacer {
  padding: 1rem 1.25rem !important;
}
/* dashboard  analytics */
.trigger-map-btn-simple {
  border-radius: 26px;
  border: 0;
  color: black;
  padding: 7px 0px;
  line-height: 24px;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  width: 119px;
  font-size: 14px;
  font-weight: 600;
}
.trigger-map-btn-simple-active {
  border-radius: 26px;
  border: 0;
  color: white;
  padding: 7px 0px;
  line-height: 24px;
  background-size: 300% 100%;
  transition: all 300ms ease-in-out;
  margin: 0 10px;
  width: 119px;
  font-size: 14px;
  background-color: #0079fd;
}
.trigger-content-section {
  overflow: hidden;
  margin-top: 12px;
  border-radius: 10px;
  height: 375px;
  box-shadow: 0px 6px 9px #d4d1d18a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listView-Container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  font-size: 14px;
}
.icon-area {
  height: 30px;
  width: 30px;
  text-align: center;
  display: inline-block;
}
.listView-item {
  display: flex;
  margin-top: 12px;
  background-color: rgb(248, 248, 248);
  border-radius: 14px;
  justify-content: space-between;
  padding: 21px 18px 15px 18px;
  align-items: center;
  cursor: pointer;
}
.subText {
  color: grey;
  display: block;
  font-size: 12px;
}
.modalTriggerTitle {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 5px;
}
.modalTriggerTitle .head {
  display: flex;
}
.modalTriggerHeader {
  margin-bottom: 15px;
}
.modalTriggerBody {
  /* overflow-y: scroll; */
  padding-right: 10px;
}
.modalTriggerTitle .dateTime,
.dateTimeHeader {
  display: flex;
  margin: 10px 0px;
  border-bottom: 1px solid #88888838;
  justify-content: space-between;
  padding-bottom: 10px;
  text-align: center;
}
.modalTriggerTitle .dateTime div,
.dateTimeHeader div {
  flex-grow: 0;
  flex-basis: 25%;
}
.modalTriggerTitle .dateTime div:first-child {
  color: black;
  font-weight: 700;
  font-size: 16px;
}
.myMap {
  height: 370px;
  width: 100%;
  margin: 0px auto;
}
.custom-marker {
  color: rgb(49, 198, 254);
  /* padding: 15px 10px; */
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  /* background: white; */
  /* width: 50px;
  height: 50px; */
  font-size: 12px;
  cursor: pointer;
}
.custom-marker .number-circle {
  background: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  /* padding: 10px 6px; */
  border: 2px solid #fff;
  color: #000;
  text-align: center;
  font: 14px Arial, sans-serif;
  position: absolute;
  top: -2px;
  left: 38px;
}
.map-info-popup {
  position: absolute;
  bottom: 0;
  left: -405px;
  color: white;
  width: 315px;
  height: auto;
  background-color: #0079fd;
  border-radius: 11px;
  padding: 9px 11px;
  font-size: 14px;
  cursor: pointer;
}
.map-info-popup .subText {
  color: #a4c1e0;
  text-align: left;
  display: block;
  font-size: 12px;
}
.map-info-popup .popText {
  color: #ffffff;
  font-size: 11px;
  padding-left: 5px;
  margin-top: 3px;
}
.map-info-popup .popText2 {
  color: #ffffff;
  font-size: 12px;
  padding-left: 5px;
  margin-top: 3px;
}

.arrow_box {
  position: relative;
  background: #1e1e2d;
  border: 2px solid #1e1e2d;
  color: #fff;
  padding: 3px;
}
.arrow_box:after,
.arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(85, 85, 85, 0);
  border-right-color: #555;
  border-width: 10px;
  margin-top: -10px;
}
.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 13px;
  margin-top: -13px;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}
.modal {
  background-color: rgba(30, 30, 45, 0.8) !important;
}

.breathalyzer-test-list .block {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 5px;
}

.breathalyzer-test-list .red-block {
  background-color: #ff6f58;
}
.breathalyzer-test-list .green-block {
  background-color: #2fe192;
}
.breathalyzer-test-list .yellow-block {
  background-color: #fff200;
}
.breathalyzer-test-list .text {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}
.breathalyzer-test-list .red-text {
  color: #ff6f58;
}
.breathalyzer-test-list .green-text {
  color: #2fe192;
}
.breathalyzer-test-list .yellow-text {
  color: #fff200;
}
.drawer-course {
}
.drawer-details-view .title,
.drawer-center .center-title,
.drawer-course .course-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #182838;
}
.drawer-center .center-phone {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  display: inline-block;
  padding: 4px 6px;
  background: #f2f2f2;
  border-radius: 2px;
}
.center-phone .phone-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  text-transform: uppercase;

  color: #182838;
}
.drawer-details-view .c-item,
.drawer-center .c-item,
.drawer-course .c-item {
  display: flex;
  flex-direction: column;
}
.drawer-details-view .c-item .c-label,
.drawer-center .c-item .c-label,
.drawer-course .c-item .c-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7f8188;
  margin-bottom: 8px;
}
.drawer-details-view .c-item .c-value,
.drawer-center .c-item .c-value,
.drawer-course .c-item .c-value {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #182838;
}
.p-modal {
  background: #fff !important;
  margin: 50% 50px;
  padding: 20px 50px;
  border-radius: 8px;
  width: 950px;
}

.staff-page .roles-permissions-section .section-block{
  padding: 3px;
  background: #F7F8FA;
  margin-bottom: 10px;
  /* box-shadow: 0px 0px 4px rgba(61, 61, 69, 0.1); */
  border-radius: 2px;
}
input.my-dictate-checkbox {
  display: none;
}

.date-width {
  width: "300px"
}

.cause-width {
  width: "100px"
}

.profile-section-label{
  font-size: 14px;
  color: #7F8188;
}
.profile-section-label-value{
  font-size: 14px;
    color: #000;
    margin-bottom: 10px;
}

/* .arrow {
  width: 50px;
  height: 50px;
  border: 3px solid;
  border-radius: 50%;
  position: relative;
}

.arrow:before {
  content: "";
  display: block;
  width: 10px;
  height: 50px;
  background: #fff;
  position: absolute;
  bottom: 0;
  top: 0;
  right: -6px;
  margin: auto;
}

.arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  position: absolute;
  bottom: 50px;
  right: -10px;
} */

/* user-basic-detail */
.user-basic-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.user-basic-profile .user-avatar {
  height: 60px;
  display: flex;
  align-items:center;
  justify-content: center;
}

.user-basic-detail .basic-detail {}

.user-basic-detail .basic-detail .name {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #182838;
}

.user-basic-detail .basic-detail .badge {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #46B4FE;
}

.user-basic-detail .basic-detail .age,
.user-basic-detail .basic-detail .gender {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #7F8188;
}

.user-basic-detail .basic-detail .phone {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #182838;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.05);
}
.user-basic-detail .user-services{
  word-break: break-all;
}
.user-basic-detail .user-services .tag{
  font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    background-color: #ebeff2;
    color: #7f8188;
    margin: 2px 5px 5px 0px;
    white-space: nowrap;
    padding: 3px;
    border-radius: 4px;
}
.user-basic-detail .user-notification{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.user-basic-detail .user-notification .settings{
  font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7F8188;
}
.chart-chat-tab .image{

}
.chart-chat-tab .name,.chart-friends-tab .name {
  font-size: 16px;
  color:#182838;
  font-weight: 600;
}
.chart-chat-tab .chat,.chart-friends-tab .chat {
  font-size: 14px;
  font-weight: 400;
  color:#7F8188;

}
.chart-friends-tab .badge{
color: #46B4FE;
}
.chart-posts-tab .date {
font-size: 14px;
  font-weight: 400;
  color: #7F8188;
}
.team-grid {
  color: #313437;
  background-color: #fff;
}

.team-grid p {
  color: #7d8285;
}

.team-grid h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width:767px) {
  .team-grid h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.team-grid .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.team-grid .intro p {
  margin-bottom: 0;
}

.team-grid .people {
  padding: 50px 0;
}

.team-grid .item {
  margin-bottom: 30px;
}

.team-grid .item .box {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.team-grid .item .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 148, 255, 0.75);
  transition: opacity 0.15s ease-in;
  opacity: 0;
  padding-top: 80px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.team-grid .item:hover .cover {
  opacity: 1;
}

.team-grid .item .name {
  font-weight: bold;
  margin-bottom: 8px;
}

.team-grid .item .title {
  text-transform: uppercase;
  font-weight: bold;
  color: #bbd8fb;
  letter-spacing: 2px;
  font-size: 13px;
  margin-bottom: 20px;
}

.team-grid .social {
  font-size: 18px;
}

.team-grid .social a {
  color: inherit;
  margin: 0 10px;
  display: inline-block;
  opacity: 0.7;
}

.team-grid .social a:hover {
  opacity: 1;
}
.video-call-logs-tabs .video-info-section {
  background: #F7F8FA;
    border-radius: 4px;
    padding: 8px;
}
.video-call-logs-tabs .video-info-section .date{
font-size: 16px;
color: #182838;
font-weight: 600;
}
.video-call-logs-tabs .video-info-section .time-label {
  font-size: 14px;
    color: #7F8188;
    padding-right: 10px;
}
.video-call-logs-tabs .video-info-section .value {
  font-size: 14px;
    color: #182838;
}

.clientChart-surveys-tab .survey-list-section {
  background: #F7F8FA;
  border-radius: 4px;
  padding: 8px;
}

.clientChart-surveys-tab .survey-list-section .date {
  font-size: 16px;
  color: #182838;
  font-weight: 600;
}

 .clientChart-task-tab .date {
  font-size: 16px;
  color: #182838;
  font-weight: 600;
}

.clientChart-growthPlan-tab .date {
  font-size: 16px;
  color: #182838;
  font-weight: 600;
}
 .clientChart-course-tab .date {
  font-size: 16px;
  color: #182838;
  font-weight: 600;
}





.clientChart-surveys-tab .survey-list-section .time-label {
  font-size: 14px;
  color: #7F8188;
  padding-right: 10px;
}

.clientChart-surveys-tab .survey-list-section .value {
  font-size: 14px;
  color: #182838;
}
/* .MuiTabScrollButton-root.Mui-disabled{
opacity: 0.8!important;
} */
.markAsDone {
  background: #F7F8FA;
  border-radius: 4px;
}

.markAsInComplete {
  background: rgba(0, 158, 247, 0.1);
  border-radius: 4px;
}

.markAsUnassign {
  background: rgba(254, 242, 242, 1);
  border-radius: 4px;
}
/* Assessments Section */
.assessments-section{

}
.assessments-section .assessments-card{
  background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 4px 8px rgba(71, 72, 75, 0.06);
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 10px;
}
.assessments-section .assessments-card .new{
  text-align: center;
    position: relative;
      height: 170px;
}
.assessments-section .assessments-card .new .overdue {
  background: rgb(255,43,43,0.05);
  border-radius: 2px;
  padding: 10px;
  color: #FF2C2C;
  font-weight: 600;
  font-size: 16px;
}
.assessments-section .assessments-card .new .name{
  font-weight: 600;
    font-size: 14px;  
    color: #182838;
}
.assessments-section .assessments-card .new .date-text {
  font-weight: 400;
  font-size: 12px;
  color: #7F8188;
}
.assessments-section .assessments-card .new .due-date-value{
  font-weight: 400;
    font-size: 13px;  
    color: #FF2C2C;
}
.assessments-section .assessments-card .completed{
    text-align: center;
    position: relative;
    height: 170px;
 
}
.assessments-section .assessments-card .completed .score{
  background: rgba(242, 250, 255, 1);
  border-radius: 2px;
  padding: 10px;
  color: #182838;
  font-weight: 600;
    font-size: 16px;
}
.assessments-section .assessments-card .completed .submitted{
  background: rgba(242, 250, 255, 1);
  border-radius: 2px;
  padding: 10px;
  color: #182838;
  font-weight: 600;
    font-size: 16px;
}
.assessments-section .assessments-card .completed .name{
  font-weight: 600;
    font-size: 14px;
    color: #182838;
    margin-top: 10px;
      display: block;
}
.assessments-section .assessments-card .completed .date-text{
  font-weight: 400;
    font-size: 12px;
  
    color: #7F8188;
}
.assessments-section .assessments-card .completed .completed-date-value{
  font-weight: 400;
    font-size: 13px;
    color: #2FE192
}


.assessments-section .assessments-card .completed .view-replies {
  font-weight: 600;
  font-size: 14px;
  color: #009EF7;

}
.assessments-section .assessments-card .archived{
      text-align: center;
    position: relative;
    height: 170px;
}
.assessments-section .assessments-card .archived .status {
  background: #F7F8FA;
  border-radius: 2px;
  padding: 10px;
  color: #7F8188;
  font-weight: 400;
    font-size: 16px;
}
.assessments-section .assessments-card .archived .name {
  font-weight: 600;
  font-size: 14px;
  color: #182838;
}

.assessments-section .assessments-card .archived .date-text {
  font-weight: 400;
  font-size: 12px;
  color: #7F8188;
}

.assessments-section .assessments-card .archived .date-value {
  font-weight: 400;
  font-size: 13px;
  color: #182838
}
.assessments-section .assessments-card .new .block,
.assessments-section .assessments-card .archived .block,
.assessments-section .assessments-card .completed .block {
  position: absolute;
  bottom: 0px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: block;
}
.assessments-section .assessments-card .new .assigned-date,
.assessments-section .assessments-card .new .due-date,
.assessments-section .assessments-card .completed .assigned-date,
.assessments-section .assessments-card .completed .completed-date ,
.assessments-section .assessments-card .archived .assigned-date,
.assessments-section .assessments-card .archived .archived-date
{
  display: grid;
}
/* react-phone-number-input */

/* .custom-phone-input input {
  border: none;
} */

/* Virtual Second Opinion */
.virtual-second-opinion-section {}

.virtual-second-opinion-section .opinion-section {
  padding: 15px;
  background: #F7F8FA;
  border-radius: 4px;
  margin-bottom: 20px;
}

.virtual-second-opinion-section .opinion-section .opinion-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #182838;
}

.virtual-second-opinion-section .opinion-section .opinion-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #7F8188;
  text-align: justify;
  display: block;
}

.virtual-second-opinion-section .opinion-section .actions {}

.virtual-second-opinion-section .opinion-section .actions .agreed {
  font-weight: 600;
  font-size: 14px;
  color: #2FE192;
}

.virtual-second-opinion-section .opinion-section .actions .disagreed {
  font-weight: 600;
  font-size: 14px;
  color: #FF2C2C;
}

/* Task Drawer Section */
.task-drawer-section {}

.markAsDone .date-label,
.markAsInComplete .date-label,
.markAsUnassign .date-label {
  font-weight: 400;
  font-size: 14px;
  color: #7F8188;
}

.markAsDone .value,
.markAsInComplete .value,
.markAsUnassign .value {
  font-weight: 400;
  font-size: 14px;
  color: #182838;
}
.date-time-style{
  color:#7F8188;
  font-weight: 400;
    font-size: 12px;
}
.profile-section-details>div,.profile-section-Stats>div{
  min-height: 62px;
}

/* **************************Timeline start ****************************************/

.timeline .box .date {
  /* background: #e3f4ff; */
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #515151;
  padding: 5px 15px;
  display: inline-block;
}

.timeline .box .icon {
  background: #fff;
  box-shadow: 0px 4px 9px rgb(0 0 0 / 10%);
  border-radius: 40px;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline .box .time {
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
    font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #979797;
}

/* .timeline .box .time:after {

  border-bottom: 1px solid #979797;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 39%;
  bottom: -40px;
  margin: 0 auto;
  transform: rotate(90deg);
} */
.event-list .event-list-group {
  flex-direction: column;
  display: flex;
}

.event-list .ant-checkbox-group-item {
  margin-bottom: 5px !important;
}

.event-list .event-list-group .ant-checkbox-wrapper {
  margin: 10px 0px;
}

.timetravel-filter {
  height: 450px;
  overflow-y: scroll;
}

/* .timeline .box div:not(:last-child) div.time:after {
  border-bottom: 1px solid #979797;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  width: 25%;
  bottom: -45px;
  margin: 0 auto;
  transform: rotate(90deg);
} */

.timeline .box .row_default .event-details {
  background: #f8f8f8;
}

.timeline .box .row_info .event-details {
  background: #f2f8ff;
}

.timeline .box .row_success .event-details {
  background: rgba(1, 217, 121, 0.05);
}

.timeline .box .row_warning .event-details {
  background: rgba(255, 133, 0, 0.05);
}

.timeline .box .row_danger .event-details {
  background: rgba(255, 244, 244, 0.7);
}

.timeline .box .row_orange .event-details {
  background: rgba(255, 184, 0, 0.05);
}

.timeline .box .row_green .event-details {
  background: rgba(1, 217, 121, 0.05);
}

.timeline .box .event-details {
  /* background: #f8f8f8; */
  border-radius: 8px;
  padding: 23px 35px;
}

.timeline .box .row_default .event-details .header-content {
  color: #515151;
}

.timeline .box .row_info .event-details .header-content {
  color: rgba(0, 120, 255, 1);
}

.timeline .box .row_success .event-details .header-content {
  color: rgba(1, 217, 121, 1);
}

.timeline .box .row_warning .event-details .header-content {
  color: rgba(255, 133, 0, 1);
}

.timeline .box .row_danger .event-details .header-content {
  color: rgba(255, 121, 121, 1);
}

.timeline .box .row_orange .event-details .header-content {
  color: rgba(255, 184, 0, 1);
}

.timeline .box .row_green .event-details .header-content {
  color: rgba(1, 217, 121, 1);
}

.timeline .box .event-details .header-content {
  font-style: normal;
  font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  /* color: #515151; */
  display: flex;
  word-break: break-word;
}

.timeline .box .event-details .desc {
  font-style: normal;
  font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  color: #828282;
}

.timelineDrawer .ant-drawer-body {
  background: #fff !important;
}

/* **************************Timeline End ****************************************/


.custom-control-label::before{
  border: none  !important;
}

.posts{
  background-color: #F7F8FA;
}

/* Tem */
.btn-newCon {
  background: unset;
  border: unset;
  color: #009EF7;
  font-weight: 700;
  margin-left: auto;
  display: table;
  text-transform: uppercase;
  position: absolute;
  /* bottom: 85px; */
  display: inline-flex;
  width: 210px;
  white-space: nowrap;
  letter-spacing: 0.1px;
  margin-left: 50px;
  font-size: 12px;
  cursor: pointer;
}

.delete-contract {
  color: #e87a7a !important;
  margin-top: 5px;
}
.delete-contract i {
  color: #e87a7a !important;
}
.templates-form .small-info {
  font-size: 12px;
  font-family: 'AvenirLTStdMedium';
  font-style: italic;
  color: #bcbcbc;
}

.templates-form .record-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #182838;
  display: inline-block;
}

.templates-form h6 {
  color: #c3b6b6;
  font-size: 12px;
}


/* Temp */

/* Small Group */
.small-group-picture-box,
.group-leader-profile-picture-box {
  background: #F7F8FA;
  border-radius: 4px;
  height: 200px;
  padding: 15px;
  text-align: center;
}

.upload-small-group-picture {
  border: 1px dashed #AAACB5;
  border-radius: 4px;
  height: 140px;
}

.upload-new-profile-picture,
.use-existing-picture {
  border: 1px dashed #AAACB5;
  padding: 5px;
  border-radius: 4px;
  height: 140px;
  width: 230px;
  
}
/* .upload-new-profile-picture {
  display: flex;
  flex-direction: column;
} */
.upload-small-group-leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.small-group-picture-box h6,
.group-leader-profile-picture-box h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.09625px;
  color: #182838;
}

.invite-box {
  height: 300px;
  border: 2px solid #AAACB5;
  border-radius: 4px;
  margin: 10px 2px;
}
/* assessment-card */
.assessment-section {
  display: flex;
  flex-wrap: wrap;
}

#assessment-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(76, 87, 125, 0.02);
  border-radius: 4px;
  width: 290px;
  height: 293px;
  margin: 5px;
  margin-bottom: 10px;
}

#assessment-card .assessment-inside {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

#assessment-card .image {
  /* border: 1px dashed #AAACB5; */
  padding-top: 5px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

#assessment-card .name {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #182838;
  text-transform: capitalize;
  margin-top: 10px;
}

#assessment-card .service-type {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #7F8188;
  margin-top: 10px;
}
#assessment-card .services {
  display: flex;
  align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

#assessment-card .services .tag {
  background: #EBEFF2;
  border-radius: 2px;
  padding: 2px 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #7F8188;
  margin-right: 5px;
}

#assessment-card .due {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #FF5D5D;
  margin-top: 10px;
}

#assessment-card .navigate {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #009EF7;
  margin-top: 10px;
}
#assessment-card .navigate span {
  cursor: pointer;
}

.assessments-page .over-due{
  font-weight: 400;
    font-size: 15px;
  
    color: #182838;
}
.manage-schedule .card.card-custom>.card-body{
padding: 0px;
}