/* Application level textarea override for textarea resize handler */
.MuiInputBase-inputMultiline {
  resize: vertical !important;
}

.MuiButton-containedSecondary {
  background-color: rgba(0, 158, 247, 1) !important;
}

.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: end;
}

/* custom-tabs */
.custom-tabs .expansion-panel .MuiButtonBase-root {
  background-color: #f5f8fa !important;
}

.custom-tabs .drawer-tabs .Mui-selected {
  background: #1e1e2d !important;
  color: white !important;
  border-radius: 4px !important;
}

.custom-tabs .drawer-tabs button {
  background: #F5F8FA !important;
}

.custom-tabs .drawer-tabs .MuiTabs-scrollButtonsDesktop {
  height: 33px;
}

.custom-tabs .MuiTab-root {
  min-height: 33px !important;
}

.custom-tabs table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.custom-tabs .table thead th {
  border-bottom: 0px;
}

.custom-tabs .table td {
  border-top: 0px solid #ebedf3;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* .modal {
  z-index: 1351 !important;
} */
.Toastify__toast-container {
  z-index: 1500 !important;
}

/* Card Header */
.card.card-custom>.card-header {
  align-items: center;
}

/* Export Button */
.export-button button {
  color: #3783e7 !important;
  border: 1px solid rgba(55, 131, 231, 0.5) !important;
  padding: 5px 15px !important;
  background: transparent !important;
}

/* Stepper */
.MuiStepIcon-text {
  fill: transparent !important;
  /* hidding step count*/
}

.MuiSvgIcon-root {
  width: 1.1em !important;
  height: 1.1em !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.custom-tabs .clientChart-surveys-tab .MuiButtonBase-root {
  background-color: #3783e7 !important;
}

.custom-tabs .clientChart-growthPlan-tab .MuiButtonBase-root {
  background-color: #3783e7 !important;
}

/* .custom-tabs .clientChart-task-tab .MuiButtonBase-root {
  background-color: #3783e7 !important;
} */
/* .custom-tabs .clientChart-course-tab .MuiButtonBase-root {
  background-color: #3783e7 !important;
} */

.table-hover tbody tr:hover {
  cursor: pointer !important;
}

/* column width no-wrap */
.table:not(.table-bordered) thead th,
.table:not(.table-bordered) thead td {
  white-space: nowrap;
}

.makeStyles-chip-474 {
  margin: 2px 2px;
}

a {
  text-decoration: none !important;
}

/* this is related error color  */
.MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #FF2C2C !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #FF2C2C !important;
}

.MuiFormLabel-root.Mui-error,
.MuiFormHelperText-root.Mui-error {
  color: #FF2C2C !important;
}


/* .MuiInputLabel-formControl {
  transform: translate(10px, 10px) scale(1) !important;
} */

/*this is related to rectangular fields color*/
.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(5px, -15px) scale(1) !important;
}


/* .MuiInputLabel-filled {
  z-index: 1;
  transform: translate(15px, 12px) scale(1);
  pointer-events: none;
} */

.MuiInputLabel-filled,
.MuiInputLabel-formControl {
  z-index: 1;
  transform: translate(5px, -15px) scale(1) !important;
  pointer-events: none;
}



/* this is related to button shadows*/
.MuiFab-root {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%) !important;
}


/*dropdown z-index*/

.MuiPaper-root {
  z-index: 3 !important;
}


.MuiOutlinedInput-notchedOutline legend {
  max-width: 0 !important;
}


.react-datepicker-popper {
  z-index: 3 !important;
}

.select__placeholder,
.select__single-value {
  margin-left: 10px !important;
}

.custom-tabs .drawer-tabs .MuiSvgIcon-root {
  border: 1.5px solid #009EF7;
  border-radius: 50%;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 0.4 !important;
}

.MuiTabScrollButton-root.Mui-disabled .MuiSvgIcon-root {
  border: 1.5px solid rgba(0, 0, 0, 0.7);
}


.MuiTabScrollButton-root.Mui-disabled .MuiSvgIcon-root path {
  stroke: rgba(0, 0, 0, 0.5) !important;
}

.custom-tabs .drawer-tabs .MuiSvgIcon-root path {
  stroke: rgb(0, 158, 247);
  stroke-linecap: round;
  stroke-linejoin: round;
}

/* .custom-tabs .drawer-tabs .MuiTabs-scrollButtonsDesktop.Mui-disabled .MuiSvgIcon-root{
  border: 1.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 50%;
} */

.drawer-details-view .c-item {
  border-bottom: 1px solid #EEF1F4 !important;
  padding: 13px 0px;
}

.toggle-button {
  display: flex;
  align-items: center;
  background-color: #fff;
}

.addNotes .MuiOutlinedInput-input {
  padding: 10px !important;
}

.modal .modal-header .close span {
  display: block !important;
}

.PhoneInputInput {
  /* position: absolute;
  top: 6px;
  left: 54px;
  width: 81%;
  border: unset; */

  position: absolute;
    top: 5px;
    left: 54px;
    width: 81%;
    border: unset;
    height: 89%;
}

.custom-phone-input {
  height: 36px;
  margin-top: 4px;
}

.custom-phone-input .PhoneInput{
    border: 1px solid rgba(0, 0, 0, 0.23);
  }